import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "react-bootstrap/Button"

const StyledContainer = styled.div`
  width: 32.5rem;
  height: auto;
  margin: 1rem auto;
  background: var(--shade);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    color: var(--gold);
  }
  @media (min-width: 576px) {
    width: 95%;
    min-height: 90%;
    margin: 2.5% auto;
    padding: 1rem;
  }
`

const StyledHeading = styled.div`
  width: 100%;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  h3 {
    color: var(--gold);
  }
`

const StyledInstructions = styled.div`
  width: 90%;
  margin: 2rem auto;
  flex: 1;
  h4 {
    color: var(--gold);
    font-size: 1.2rem;
    text-align: center;
  }
  h5 {
    color: var(--gold);
    font-size: 1.1rem;
    text-align: left;
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
  }
  p {
    color: var(--white);
    font-size: 1rem;
    text-align: left;
  }
  div {
    padding: 0.5rem 0;
  }
`

const StyledSpread = styled.div`
  width: 97.5%;

  margin: 0.25rem auto;
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 0.5rem;
  @media (min-width: 576px) {
    grid-template-columns: 12rem 12rem 12rem;
    grid-template-rows: auto;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 2rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 15rem 15rem 15rem;
    gap: 3rem;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 25rem 25rem 25rem;
    gap: 4rem;
  }
  .gatsby-image-wrapper {
    width: 100px;
    height: auto;
    object-fit: scale-down;
    margin: 0 auto;
    padding: 0 auto;
    border-radius: 1rem;
    @media (min-width: 576px) {
      width: 12rem;
      height: 20rem;
    }
    @media (min-width: 768px) {
      width: 15rem;
      height: 25rem;
    }
    @media (min-width: 1200px) {
      width: 25rem;
      height: 40rem;
    }
  }
`
const StyledThemes = styled.div`
  width: 100%;
  margin: 0.25rem auto;
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 1rem;
  h3 {
    font-size: 1.2rem;
    margin: 0 auto;
    padding: 0 auto;
  }
  @media (min-width: 576px) {
    grid-template-columns: 12rem 12rem 12rem;
    grid-template-rows: auto;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 2rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 15rem 15rem 15rem;
    gap: 3rem;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 25rem 25rem 25rem;
    gap: 4rem;
  }
  .gatsby-image-wrapper {
    width: 100px;
    height: auto;
    object-fit: scale-down;
    @media (min-width: 576px) {
      width: 12rem;
      height: 20rem;
    }
    @media (min-width: 768px) {
      width: 15rem;
      height: 25rem;
    }
    @media (min-width: 1200px) {
      width: 25rem;
      height: 40rem;
    }
  }
`

const StyledDetails = styled.div`
  width: 97.5%;
  margin: 0.25rem auto;
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-template-rows: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 0.5rem;
  p {
    color: var(--gold);
    padding-top: 0;
    margin-top: 0;
    font-size: 1rem;
  }
  @media (min-width: 576px) {
    grid-template-columns: 12rem 12rem 12rem;
    grid-template-rows: auto;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: 2rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 15rem 15rem 15rem;
    gap: 3rem;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 25rem 25rem 25rem;
    gap: 4rem;
  }
  .gatsby-image-wrapper {
    width: 100px;
    height: auto;
    object-fit: scale-down;
    @media (min-width: 576px) {
      width: 12rem;
      height: 20rem;
    }
    @media (min-width: 768px) {
      width: 15rem;
      height: 25rem;
    }
    @media (min-width: 1200px) {
      width: 25rem;
      height: 40rem;
    }
  }
`

export default function DailyResult(props) {
  const love = props.cards[`${props.love}`]
  const you = props.cards[`${props.you}`]
  const career = props.cards[`${props.career}`]
  const readings = props.readings

  const loveReading = readings.filter(reading => {
    return reading.card_number === love.card_number
  })
  const youReading = readings.filter(reading => {
    return reading.card_number === you.card_number
  })
  const careerReading = readings.filter(reading => {
    return reading.card_number === career.card_number
  })

  // console.log(loveReading)
  // console.log(love.card_number)
  // console.log(youReading)
  // console.log(you.card_number)
  // console.log(careerReading)
  // console.log(career.card_number)
  // console.log(props.love)
  // console.log("Readings", props.readings)
  // console.log(props.cards)

  return (
    <>
      <StyledContainer>
        <StyledHeading>
          <h3>Daily Tarot Reading</h3>
        </StyledHeading>
        <StyledThemes>
          <h3>Love</h3>
          <h3>You</h3>
          <h3>Career</h3>
        </StyledThemes>
        <StyledSpread>
          <GatsbyImage
            image={love.image.asset.gatsbyImageData}
            alt={love.card_name}
            style={{ border: `thin solid var(--gold)` }}
          />
          <GatsbyImage
            image={you.image.asset.gatsbyImageData}
            alt={you.card_name}
            style={{ border: `thin solid var(--gold)` }}
          />
          <GatsbyImage
            image={career.image.asset.gatsbyImageData}
            alt={career.card_name}
            style={{ border: `thin solid var(--gold)` }}
          />
        </StyledSpread>
        <StyledDetails>
          <p>{love.card_name}</p>
          <p>{you.card_name}</p>
          <p>{career.card_name}</p>
        </StyledDetails>
        <StyledInstructions>
          <h4>Your Reading</h4>
          <div>
            <h5>Love: </h5>
            {loveReading.length === 0 ? (
              <p>Other energies are causing this picture to be fuzzy.</p>
            ) : (
              <p>{loveReading[0].card_love_reading} </p>
            )}{" "}
          </div>
          <div>
            <h5>You: </h5>
            {youReading.length === 0 ? (
              <p>Hard to read in this particular situation.</p>
            ) : (
              <p> {youReading[0].card_you_reading} </p>
            )}
          </div>
          <div>
            <h5>Career:</h5>
            {careerReading.length === 0 ? (
              <p>The cards are unclear on this topic.</p>
            ) : (
              <p>{careerReading[0].card_career_reading} </p>
            )}
          </div>
        </StyledInstructions>
        <Link
          to="/readings"
          className="w-100 mt-2 mb-3 d-flex justify-content-center"
        >
          <Button
            className="w-50"
            type="button"
            style={{ border: `thin solid var(--gold)` }}
          >
            More Readings
          </Button>
        </Link>
      </StyledContainer>
    </>
  )
}
