import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import DailyResult from "../components/dailyresult"

const StyledContainer = styled.div`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
`

export default function ReadingResult({ data }) {
  const cards = data.cards.nodes
  const readings = data.readings.nodes

  const loveRandom = Math.floor(Math.random() * 78)
  const youRandom = Math.floor(Math.random() * 78)
  const careerRandom = Math.floor(Math.random() * 78)

  return (
    <>
      <SEO title="Daily Reading Result" />
      <StyledContainer>
        <DailyResult
          cards={cards}
          readings={readings}
          love={loveRandom}
          you={youRandom}
          career={careerRandom}
        />
      </StyledContainer>
    </>
  )
}

export const query = graphql`
  query dailyQuery {
    cards: allSanityTarot {
      nodes {
        id
        card_name
        card_number
        arcana
        card_suit
        image {
          asset {
            gatsbyImageData(
              width: 90
              fit: FILL
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    readings: allSanityDailyreadings {
      nodes {
        card_number
        id
        card_you_reading
        card_love_reading
        card_career_reading
      }
    }
  }
`
